import { Fragment } from "react";
import classNames from "classnames";
import Image from "components/imageNew/image";
import { DatoImageType } from "lib/types";
import {
  Person as PersonType,
  SponsorDetails,
  TalkData,
} from "content-ui/talks/types";
import { personImageLoader } from "./utils";

type PersonProps = {
  data: PersonType;
  withImage: boolean;
};

export const Person = ({ data, withImage }: PersonProps) => {
  const isModerator = !data.isSpeaker;
  return (
    <div className="hidden gap-x-[10px] lg:flex" data-testid={"person"}>
      {withImage ? (
        <Image
          loader={personImageLoader}
          className="h-[46px] w-[46px] object-cover [clip-path:polygon(0_14%,11%_0,100%_0,100%_83%,91%_100%,0_100%)]"
          src={data.image.url}
          width={data.image.width}
          height={data.image.height}
          alt={data.name}
          quality={75}
          placeholder="blur"
          blurDataURL={data.image.blurUpThumb}
        />
      ) : null}
      <div className="text-[14px]">
        <p>
          <span
            className={classNames("", {
              "font-bold": isModerator,
            })}
          >
            {data.name}
          </span>
          {data.role && `, ${data.role}`}
        </p>
        {data.company ? <p className="">@ {data.company}</p> : null}
      </div>
    </div>
  );
};

type PersonMobileProps = {
  data: PersonType;
  isLast: boolean;
};

export const PersonMobile = ({ data, isLast }: PersonMobileProps) => {
  const isModerator = !data.isSpeaker;

  return (
    <span className={"text-[14px] text-mono-70 lg:hidden"}>
      <span className={classNames(isModerator && "font-bold")}>
        {data.name}
      </span>
      {data.role && <span className={"hidden md:inline"}>, {data.role}</span>}
      {data.company ? ` @ ${data.company}` : null}
      {!isLast && `  |`}{" "}
    </span>
  );
};

// Sort speakers first
function compareByIsSpeaker(a, b) {
  if (a.isSpeaker === b.isSpeaker) return 0;
  return a.isSpeaker ? -1 : 1;
}

export const TalkBody = ({ talk }: { talk: TalkData }) => {
  if (talk.people.length === 0) return null;
  return (
    <div className="pointer-events-none inline-block lg:grid lg:grid-cols-3 lg:gap-y-2">
      {talk.people.sort(compareByIsSpeaker).map((person, i) => {
        return (
          <Fragment key={i}>
            <Person data={person} withImage={false} />
            <PersonMobile data={person} isLast={i === talk.people.length - 1} />
          </Fragment>
        );
      })}
    </div>
  );
};

export const SponsorLogoAndText = ({
  image,
  sponsorDetails,
  variant = "eventCard",
}: {
  image?: DatoImageType;
  sponsorDetails?: SponsorDetails;
  variant?: "eventCard" | "singlePage";
}) => {
  image = image ?? sponsorDetails?.logo;
  if (!image) return null;
  return (
    <div
      data-testid={"sponsorLogoAndText"}
      className={classNames("", {
        "flex flex-row items-center justify-between md:flex-col lg:mb-[14px] lg:mt-[30px] lg:flex-row":
          variant === "eventCard",
        "flex gap-x-[14px]": variant === "singlePage",
      })}
    >
      <p
        className={classNames(
          "inline-flex items-center font-bold text-[14px] leading-[130%] text-mono-40",
          {
            "md:mb-[9px] lg:mb-0": variant === "eventCard",
          }
        )}
      >
        {sponsorDetails?.sponsorshipType ?? "Sponsored by"}
      </p>
      <div className={classNames("h-[42px] w-[105px]")}>
        <Image
          className={classNames("h-full w-full object-contain", {
            "self-end": variant === "eventCard",
          })}
          src={image.url}
          width={image.width}
          height={image.height}
          sizes="50vw"
          alt={image.alt || "Sponsor logo"}
          quality={100}
        />
      </div>
    </div>
  );
};
